.artist_tab .custom_tabs .react-tabs {
  padding: 0 !important;
  height: 100%;
  position: relative;
}

.artist_tab .custom_tabs .react-tabs__tab-list {
  margin: 0 !important;
}

.artist_tab .custom_tabs .react-tabs__tab--selected {
  color: #222222 !important;
  border-bottom: none !important;
}

.artist_tab .custom_tabs .react-tabs__tab-list {
  border-bottom: none !important;
  font-size: 14px;
  font-family: Pretendard-Medium;
  color: #c9c9c9;
  height: 20px;
}

.artist_tab .custom_tabs .react-tabs__tab {
  margin-right: 20px !important;
  display: inline-block;
  border: none;
  position: relative;
  list-style: none;
  cursor: pointer;
  text-align: center;
}

.artist_tab .custom_tabs .react-tabs__tab-panel {
  margin-top: 20px;
}
