.slick-dots {
  top: -25px;
  height: 0;
}

.slick-dots li button::before {
  opacity: 0.3;
}

.slick-dots li.slick-active button::before {
  opacity: 1;
}

.slick-dots li {
  margin: 0;
  padding: 0;
  width: 6px;
  height: 6px;
  padding: 4px;
}

.slick-dots li:first-child {
  margin-left: -7px;
}

.slick-dots li button {
  margin: 0;
  height: 0;
}
