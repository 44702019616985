/* .react-tabs__tab-panel--selected {
  animation: slideeffect 500ms ease-out;
  opacity: 1;
}

@keyframes slideeffect {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
} */

/* 더블 클릭시 사라지는 css 방지 */
.react-tabs [role='tabs']:focus {
  box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.06);
  border-bottom: 2px solid #242424;
}

.react-tabs [role='tab']:focus {
  box-shadow: none;
  border-bottom: 2px solid #242424;
}

.react-tabs [role='tab']:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: transparent;
}
/* 더블 클릭시 사라지는 css 방지 */

.artist_tab {
  padding: 20px 0 0 0 !important;
  height: 100%;
}
/*
.artist_tab .react-tabs {
  padding: 20px 0 0 0 !important;
  height: 100%;
} */

.artist_tab .react-tabs__tab-list {
  margin: 0 0 30px 0 !important;
}

.artist_tab .react-tabs__tab--selected {
  color: #242424 !important;
  border-bottom: 2px solid #242424 !important;
}

.artist_tab .react-tabs__tab-list {
  border-bottom: none !important;
  font-size: 16px;
  font-family: Pretendard-Medium;
  color: #c9c9c9;

  display: flex;
  justify-content: center;
  height: 40px;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  width: 100%;
  height: 40px;
  box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.06);
}

.artist_tab .react-tabs__tab {
  margin: 0;
  padding: 0;
  display: inline-block;
  border: none;
  position: relative;
  list-style: none;
  cursor: pointer;
  text-align: center;
}

.artist_tab .react-tabs__tab-panel {
  margin-top: 70px;
}
