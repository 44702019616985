.youtube-in-text-wrapper {
  padding:16px 0px 32px 0px;
  border:none;
  min-height:90px;
  height:100%;
  font-size: 14px;
    font-family: Pretendard-Regular;
    cursor: text;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-align: start;
}

.youtube-in-text {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.youtube-in-text iframe,
.youtube-in-text object,
.youtube-in-text embed {
  position: absolute;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
}