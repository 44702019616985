.link-in-text-wrapper {
  font-family: Pretendard-Regular;
  height: 100%;
  margin: 8px 0px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  background-color: transparent;

  border: none;
  resize: none;
}

