@font-face {
  font-family: NotoSansCJKkr-Black;
  src: url('../resources') format('opentype');
}
@font-face {
  font-family: NotoSansCJKkr-Bold;
  src: url('../resources/fonts/NotoSansCJKkr/NotoSansCJKkr-Bold.otf')
    format('opentype');
}
@font-face {
  font-family: NotoSansCJKkr-DemiLight;
  src: url('../resources/fonts/NotoSansCJKkr/NotoSansCJKkr-DemiLight.otf')
    format('opentype');
}
@font-face {
  font-family: NotoSansCJKkr-Light;
  src: url('../resources/fonts/NotoSansCJKkr/NotoSansCJKkr-Light.otf')
    format('opentype');
}
@font-face {
  font-family: NotoSansCJKkr-Medium;
  src: url('../resources/fonts/NotoSansCJKkr/NotoSansCJKkr-Medium.otf')
    format('opentype');
}
@font-face {
  font-family: NotoSansCJKkr-Regular;
  src: url('../resources/fonts/NotoSansCJKkr/NotoSansCJKkr-Regular.otf')
    format('opentype');
}
@font-face {
  font-family: NotoSansCJKkr-Thin;
  src: url('../resources/fonts/NotoSansCJKkr/NotoSansCJKkr-Thin.otf')
    format('opentype');
}
@font-face {
  font-family: RixYeoljeongdoPR;
  src: url('../resources/fonts/RixYeoljeongdo_Pro/RixYeoljeongdo_Pro Regular.otf')
    format('opentype');
}

@font-face {
  font-family: GongGothicOTFBold;
  src: url('../resources/fonts/gongGothic/Gong Gothic OTF Bold.otf')
    format('opentype');
}
@font-face {
  font-family: GongGothicOTFLight;
  src: url('../resources/fonts/gongGothic/Gong Gothic OTF Light.otf')
    format('opentype');
}
@font-face {
  font-family: GongGothicOTFMedium;
  src: url('../resources/fonts/gongGothic/Gong Gothic OTF Medium.otf')
    format('opentype');
}

@font-face {
  font-family: Pretendard-Bold;
  src: url('../resources/fonts/Pretendard/Pretendard-Bold.otf')
    format('opentype');
}
@font-face {
  font-family: Pretendard-Medium;
  src: url('../resources/fonts/Pretendard/Pretendard-Medium.otf')
    format('opentype');
}
@font-face {
  font-family: Pretendard-Regular;
  src: url('../resources/fonts/Pretendard/Pretendard-Regular.otf')
    format('opentype');
}

  html {
    background-color: black;
    font-size: 16px;
    margin: 0;
    padding: 0;
    font-family: Pretendard-Regular;
    color: #242424;
    min-width: 320px;
    display: flex;
    justify-content: center;
  }


body {
  background-color: black;
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-family: Pretendard-Regular;
  color: #242424;
  margin: 0 auto;
}

body .body_wrapper {
  min-width: 320px;
  max-width: 420px;
  width: 100vw;
}

body #header {
  min-width: 320px;
  max-width: 420px;
  width: 100vw;
}



h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  padding: 0;
  margin: 0;
}

a {
  color: #000000;
  text-decoration: none;
}

li {
  list-style: none;
}

@media only screen and (max-width: 421px) {
  html,
  body {
    font-size: 14px;
    width: 100vw;
  }
}

/* 더보기 textarea */
.textarea {
  font-family: Pretendard-Regular;
  overflow: hidden;
  resize: none;
  padding: 0;
  border: none;
}

/* 전역 로딩 css */
.loading_outer {
  /* background: #000000cc; */
  background: rgb(51, 51, 51);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 420px;
  min-width: 320px;
  width: 100%;
  height: 100vh;
  z-index: 9998;
  opacity: 0.2;
}

.loading_inner {
  z-index: 9999;
}

/* .loading_inner {
  position: absolute;
  top : 30%;
  left : 47%;
} */

/* youtube plaer 화면 비율 css */
.youtube_wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.youtube_player {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

*:focus {
  outline: none !important;
}

.bm-burger-button > button {
  right: 20px;
  width: 32px !important;
  left: initial !important;
}

/* ::-webkit-scrollbar {
  width: 0px;
} */

::placeholder {
  color: rgba(148, 148, 148, 0.6);
}

#artist_scroll {
  height: 100%;
  overflow-y: scroll;
}

.scroll_div > div {
  padding: 6px 0;
  cursor: pointer;
}

.scroll_div > div:first-child {
  padding-top: 16px;
}

.scroll_div > div:last-child {
  padding-bottom: 0px;
}






/* pagination custom css */
.pagination-ul {
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0;
  padding: 0;
  margin-top:28px;
}

.pagination-li {
  min-width: 32px;
  text-align: center;
  list-style: none;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;

  font-family: Pretendard-Regular;
  font-size: 14px;

  color: #949494;
}



.pagination-li a {
  color : #949494;
}

.current-page a {
  color : #242424;
}

.test-dragging.test-dragging.test-dragging {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
}

.test:focus-visible {
  border-color: #242424;
  box-shadow: 0 0 0 3px #24242480;
}